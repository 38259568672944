import React from "react";
import { Link as ReactLink } from "react-router-dom";

import { Layout, Link, Select, Text } from "tarmac-v3";
import useDeviceType from "src/hooks/useDeviceType";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import i18n from "src/i18n";
import useLocalStorage from "src/hooks/useLocalStorage";
import { LANGUAGES, LANGUAGE_VARIATIONS } from "src/common/i18n-consts";
import useInitialSelectedLanguage from "src/hooks/useInitialSelectedLanguage";
import CImage from "../cockpit/blocks/CImage";

const LoginWrapper = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isMobile = deviceType !== "desktop";
  const { search } = useLocation();

  const urlQuery = new URLSearchParams(search);

  const { initialSelectedLanguage, initialSelectedLanguageVariation } = useInitialSelectedLanguage(urlQuery);

  const [language, setLanguage] = useLocalStorage("user_language", initialSelectedLanguage);
  const [, setLanguageVariation] = useLocalStorage("user_language_variation", initialSelectedLanguageVariation);

  const languageOptions = [
    {
      label: "🇫🇷 Français",
      value: LANGUAGES.FR,
    },
    {
      label: "🇬🇧 English",
      value: LANGUAGES.EN,
    },
    {
      label: "🇪🇸 Español",
      value: LANGUAGES.ES,
    },
    {
      label: "🇩🇪 Deutsch",
      value: LANGUAGES.DE,
    },
  ];

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);

    setLanguageVariation(newLanguage === LANGUAGES.EN ? LANGUAGE_VARIATIONS.GB : newLanguage);
  };

  return (
    <Layout
      direction="column"
      spacing={isMobile ? 2 : 0}
      alignItems="center"
      justifyContent="center"
      fullWidth
      sx={{
        paddingX: isMobile ? 2 : 4,
        paddingY: 2,
        background:
          "radial-gradient(87.5% 87.5% at 50% 50%, rgba(255, 255, 255, 0) 39.12%, rgba(1, 86, 62, 0.2) 100%),linear-gradient(0deg, #f9f9fb 0%, #fbf8ef 100%)",
        height: "100vh",
      }}
    >
      <Layout direction="column" alignSelf={isMobile ? "center" : "flex-start"}>
        <CImage label="fleet_newLogo_wdsjyf" width={92} />
      </Layout>
      <Layout
        direction="column"
        spacing={4}
        fullHeight
        justifyContent={isMobile ? "space-between" : "center"}
        fullWidth={isMobile}
      >
        {children}
        <Layout direction="column" spacing={2}>
          <Layout direction="row" alignItems="center" justifyContent="center" spacing={2} mobileDirection="column">
            <Text variant="body1">{t("Pas encore de compte ?")}</Text>
            <Link
              label={
                <Text color="inherit" variant="body2" bold>
                  {t("S'inscrire gratuitement")}
                </Text>
              }
              color="darkGreen"
              to="/register"
              iconRight
              sx={{
                fontWeight: 600,
              }}
              component={ReactLink}
            />
          </Layout>
          <Layout>
            <Select options={languageOptions} value={language} onChange={(newValue) => changeLanguage(newValue)} />
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LoginWrapper;
