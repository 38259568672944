import {
  faInfoCircle,
  faShieldCheck,
  faShieldXmark,
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useUserContext } from "src/contexts/UserContext";
import useDataLayer from "src/hooks/useDataLayer";
import useDeviceType from "src/hooks/useDeviceType";

import { getSpecificationValueLabel, productSpecs } from "src/tools/ProductHelpers";

import AddAndRemoveItemButton from "src/components/common/AddAndRemoveItemButton";
import MoneyFormat from "src/tools/MoneyFormat";
import { Icon, Layout, Link, Separator, Text, Tooltip, theme, IconButton } from "tarmac-v3";
import { PrinterMaintenancePrice } from "src/components/common/PrinterMaintenancePrice";
import { PrinterScannerLabels } from "src/types/models/PrinterCharacteristics.type";
import { ProductSpecificationLayout } from "src/components/common/ProductSpecificationLayout";
import { usePrinterDeviceSpecifications } from "src/hooks/usePrinterDeviceSpecifications";

const typologyTitles = {
  new: "Neuf",
  asnew: "Certifié Apple",
  refurb: "Reconditionné +",
};

const ShoppingCartModalItem = (props) => {
  const { device, deviceNumber, product, keyboard, orderInfo, reloadOrderInfo, reloadOrder, setSecureMdmCartDialog } =
    props;

  const { t } = useTranslation();
  const { user } = useUserContext();
  const deviceType = useDeviceType();
  const { trackAddToCart, trackRemoveFromCart } = useDataLayer();
  const [isExpanded, setIsExpanded] = useState(false);
  const userCurrency = user.getCompanyCurrency();
  const { isPrinterCategory, printerMaintenancePrice, printerCharacteristics } = usePrinterDeviceSpecifications(device);

  const addToShoppingCart = async () => {
    const newDevice = {
      product_id: product?.id,
      device_id: device.id,
      ...(product?.keyboard_layout_id && { keyboard_layout_id: product?.keyboard_layout_id }),
      ...(product?.delivery_country_id && { delivery_country_id: product?.delivery_country_id }),
      ...(device.order_mdm_activation_consent && { order_mdm_activation_consent: true }),
      ...(keyboard && keyboard !== "null" && { keyboard_layout: keyboard }),
    };

    try {
      await user.api.addDeviceToOrder(orderInfo.id, newDevice);
      user.api.trackEvent("ADD_TO_CART");
      // Add same product
      trackAddToCart(device.product, orderInfo);
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  };

  const removeFromShoppingCart = useCallback(async () => {
    try {
      await user.api.removeDeviceFromOrder(orderInfo.id, { device });
      trackRemoveFromCart(device.product, orderInfo);
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  }, [reloadOrderInfo, reloadOrder, orderInfo.id, user.api, device]);

  const companyDiscount = user.getCompanyDiscount(device.base_price);

  const finalPrice =
    Number(device.rent) + (companyDiscount?.offer_type === "MONTH_OFFERED" ? Number(device.base_price) / 36 : 0);
  const basePrice =
    finalPrice + (companyDiscount?.offer_type === "CURRENCY_DISCOUNT" ? Number(companyDiscount?.offer_value) : 0);

  const hasActiveSubscriptionMdm = user.company.company_mdm && user.company.company_mdm.auto_subscription_enabled;

  return (
    <Layout direction="column" spacing={0.2}>
      <Layout direction="row" justifyContent="space-between" alignItems="center">
        <Layout direction="row" alignItems="center" spacing={1}>
          <img
            src={
              device?.product?.product_group?.source === "FLEET"
                ? device.product.img_url
                : "/images/favicons/favicon-192.png"
            }
            alt={device?.name}
            style={{
              width: "26px",
              height: device.product.product_group?.source === "FLEET" ? "28px" : "24px",
              objectFit: "contain",
            }}
          />
          <Text variant="caption" bold>
            {t(device?.name)}
          </Text>
        </Layout>

        {device.category === "COMPUTER" && !hasActiveSubscriptionMdm && (
          <Tooltip
            title={device.order_mdm_activation_consent ? t("Sécurisé par le MDM") : t("Non sécurisé par le MDM")}
            placement="top"
          >
            <Layout>
              <IconButton
                icon={
                  <Icon
                    icon={device.order_mdm_activation_consent ? faShieldCheck : faShieldXmark}
                    color={device.order_mdm_activation_consent ? "secondary" : "warning"}
                  />
                }
                size="small"
                onClick={() => setSecureMdmCartDialog(!device.order_mdm_activation_consent ? "ENROLL" : "UNENROLL")}
                color={device.order_mdm_activation_consent ? "secondary" : "warning"}
                disabled={device.order_mdm_activation_consent}
                sx={{
                  borderRadius: "4px",
                  border: !device.order_mdm_activation_consent && `1px solid ${theme.palette.orange[500]}`,
                  "&.Mui-disabled .MuiButton-startIcon": {
                    color: device.order_mdm_activation_consent
                      ? theme.palette.secondary.main
                      : theme.palette.orange[500],
                  },
                }}
              />
            </Layout>
          </Tooltip>
        )}
      </Layout>
      <Layout direction="row" alignItems="center" justifyContent="space-between">
        <Layout
          direction="row"
          padding="0 32px"
          fullWidth
          sx={{ height: "fit-content" }}
          alignItems="center"
          spacing={1}
        >
          <Text variant="caption" color="secondary">
            <MoneyFormat value={finalPrice.toFixed(2)} />
          </Text>
          <Layout direction="row" alignItems="center" spacing={0.5}>
            {companyDiscount?.offer_type === "CURRENCY_DISCOUNT" && (
              <Text variant="caption" color="secondary" sx={{ textDecorationLine: "line-through" }}>
                <MoneyFormat value={basePrice} />
              </Text>
            )}
            <Text variant="caption" color="secondary">
              {t("/mois")}
            </Text>
            {device?.country?.name !== user.company.country && (
              <Tooltip title={t("Le loyer peut varier en fonction du lieu de livraison séléctionné.")} placement="top">
                <Icon icon={faInfoCircle} size="XS" color="grey" />
              </Tooltip>
            )}
          </Layout>
          <Separator orientation="vertical" />
          <Link
            label={
              <Text variant="caption" color="secondary">
                {isExpanded ? t("voir moins") : t("voir plus")}
              </Text>
            }
            color="grey"
            iconRight
            icon={isExpanded ? faSquareMinus : faSquarePlus}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Layout>

        <AddAndRemoveItemButton
          clickMinus={removeFromShoppingCart}
          clickPlus={addToShoppingCart}
          listLength={deviceNumber}
        />
      </Layout>
      <Layout
        direction="row"
        fullWidth
        spacing={2}
        flexWrap={deviceType !== "desktop" ? "wrap" : "nowrap"}
        justifyContent={!isExpanded && "flex-end"}
      >
        <Layout direction="column" spacing={0.5} fullWidth>
          <Layout direction="column" spacing={1} fullWidth>
            {isExpanded && (
              <Layout
                fullWidth
                direction="row"
                rowGap={2}
                columnGap={5}
                flexWrap="wrap"
                sx={{ marginTop: theme.spacing(1) }}
              >
                <Layout direction="column" spacing={0.5}>
                  <Text variant="caption">{t("État")}</Text>
                  <Text variant="body2" bold sx={{ fontSize: "14px" }}>
                    {t(typologyTitles[device.quality])}
                  </Text>
                </Layout>
                {productSpecs[device.category].map(
                  (spec) =>
                    device[spec.key] && (
                      <Layout direction="column" spacing={0.5} flexWrap="wrap">
                        <Text variant="caption">{t(spec.label)}</Text>
                        <Text variant="body1" bold sx={{ fontSize: "14px" }}>
                          {t(getSpecificationValueLabel(spec.key, device[spec.key]))}
                        </Text>
                      </Layout>
                    ),
                )}
                {isPrinterCategory && printerCharacteristics && (
                  <>
                    <ProductSpecificationLayout
                      label={t("Taille de papier compatible")}
                      value={printerCharacteristics.paper_format}
                      labelTextProps={{ variant: "caption" }}
                      valueTextProps={{ variant: "body1", bold: true, sx: { fontSize: "14px" } }}
                      spacing={0.5}
                    />
                    <ProductSpecificationLayout
                      label={t("Scanner Recto/Verso")}
                      value={PrinterScannerLabels[printerCharacteristics.scanner_type]}
                      labelTextProps={{ variant: "caption" }}
                      valueTextProps={{ variant: "body1", bold: true, sx: { fontSize: "14px" } }}
                      spacing={0.5}
                    />
                  </>
                )}
              </Layout>
            )}
          </Layout>
          {isPrinterCategory && (
            <PrinterMaintenancePrice
              printerCharacteristics={printerCharacteristics}
              printerMaintenancePrice={printerMaintenancePrice}
              currency={userCurrency}
              deviceNumber={deviceNumber}
              sx={{ marginTop: 1 }}
            />
          )}
        </Layout>
      </Layout>
      {companyDiscount?.offer_type === "MONTH_OFFERED" && (
        <Layout direction="row" spacing={1}>
          <Text variant="body2" color="darkGreen" bold>
            {t("Offre active : 1 mois offert")}
          </Text>
        </Layout>
      )}
    </Layout>
  );
};

export default ShoppingCartModalItem;
