import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Heading, Icon, Layout, Link, Select, Separator, Text, TextField } from "tarmac-v3";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import getLocalePlaceholder from "src/components/utils/getLocalePlaceholder";
import PhoneInput from "src/components/utils/PhoneInput";
import { useFormValidationContext } from "src/contexts/FormValidationContext";
import { useToastContext } from "src/contexts/ToastContext";
import useDeviceType from "src/hooks/useDeviceType";
import useInitialSelectedLanguage from "src/hooks/useInitialSelectedLanguage";
import useRegistration from "src/hooks/useRegistration";
import CImage from "../blocks/CImage";
import HasAccountAlready from "./HasAccountAlready";
import styles from "./RegistrationForm.module.scss";

const RegistrationForm = (props) => {
  const { source } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { addToast } = useToastContext();
  const { search } = useLocation();
  const deviceType = useDeviceType();
  const { errors, setErrors } = useFormValidationContext();
  const { initialSelectedLanguageVariation } = useInitialSelectedLanguage(source);

  const defaultEmail = new URLSearchParams(search).get("email");

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: defaultEmail || "",
    phone: "",
    company_size: "",
    phone_country: "",
  });

  const [, setIsPhoneValid] = useState(Boolean(userInfo?.phone));
  const [isLoading, setIsLoading] = useState(false);

  const onError = useCallback(
    (responseData) => {
      setIsLoading(false);

      if (!responseData) return;

      if (responseData.type === "invalid_email" || responseData.type === "blocked_email") {
        setErrors({ email: responseData.message });
      } else {
        addToast(t(responseData.message));
      }

      if (responseData.redirect_to) {
        const redirectUrl = new URL(responseData.redirect_to);

        history.push(redirectUrl.pathname + redirectUrl.search);
      }
    },
    [history, addToast, t, setErrors],
  );

  const { register } = useRegistration(onError);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setUserInfo({ ...userInfo, [name]: value });
    },
    [userInfo],
  );

  const onPhoneChange = useCallback(
    (phone, country) => {
      setUserInfo({ ...userInfo, phone, phone_country: country });
    },
    [userInfo],
  );

  const onSave = useCallback(
    async (ev) => {
      ev.preventDefault();
      setIsLoading(true);

      // check if every field exept company_size of userInfo is not equals to ""
      const validations = [
        {
          name: "firstName",
          message: t("Veuillez rentrer un prénom valide."),
          validator: (value) => value !== "",
        },
        {
          name: "lastName",
          message: t("Veuillez rentrer un nom valide."),
          validator: (value) => value !== "",
        },
        {
          name: "email",
          message: t("Veuillez rentrer une adresse e-mail professionnelle."),
          validator: (value) => value !== "",
        },
        {
          name: "phone",
          message: t("Veuillez rentrer un numéro de téléphone valide."),
          validator: (value) => value !== "",
        },
        {
          name: "company_size",
          message: t("Veuillez sélectionner une taille d'entreprise."),
          validator: (value) => value !== "",
        },
      ];
      const validationErrors = {};

      validations.forEach((validation) => {
        if (!validation.validator(userInfo[validation.name])) {
          validationErrors[validation.name] = validation.message;
        }
      });

      setErrors(validationErrors);

      const isEveryFieldFilled = Object.keys(userInfo).every((key) => userInfo[key] !== "");

      if (!isEveryFieldFilled) {
        setIsLoading(false);
        return;
      }

      await register(userInfo, source);

      setIsLoading(false);
    },
    [addToast, register, source, userInfo],
  );

  const companySizeOptions = useMemo(
    () => [
      {
        label: "1 - 24 employés",
        value: "1-24",
        selected: userInfo.company_size === "1-24",
      },
      {
        label: "25 - 49 employés",
        value: "25-49",
        selected: userInfo.company_size === "25-49",
      },
      {
        label: "50 - 99 employés",
        value: "50-99",
        selected: userInfo.company_size === "50-99",
      },
      {
        label: "Plus de 100 employés",
        value: "100+",
        selected: userInfo.company_size === "100+",
      },
    ],
    [userInfo.company_size],
  );

  const onSelectChange = useCallback(
    (name, value) => {
      setUserInfo({ ...userInfo, [name]: value });
    },
    [userInfo],
  );

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      phone_country: initialSelectedLanguageVariation,
    });
  }, [initialSelectedLanguageVariation]);

  return (
    <form onSubmit={onSave} style={{ width: "100%" }}>
      <Layout
        direction="column"
        className={`${styles.registrationWrapper} ${styles.bgImg}`}
        fullWidth
        isScrollable
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Layout className={styles.registrationPopUp} direction={deviceType === "desktop" ? "row" : "column"}>
          <Layout className={styles.registrationBenefits} justifyContent="flex-start">
            <Layout className={styles.logoContainer}>
              <CImage width="93" label="fleet_newLogo_wdsjyf" />
            </Layout>
            <Layout spacing={3}>
              <Heading variant="h4">{t("Gérez vos équipements simplement")}</Heading>
              <Text variant="body2" className={styles.textBenefits}>
                {t("Une solution tout-en-un pour l'acquisition, la gestion et le renouvellement de vos équipements :")}
              </Text>
              <Layout spacing={10}>
                <Layout spacing={2}>
                  <Layout spacing={2} direction="row">
                    <Icon icon={faCheckCircle} size="S" color="darkGreen" />
                    <Text variant="caption">{t("Ordinateurs et mobilier haut de gamme")}</Text>
                  </Layout>
                  <Layout spacing={2} direction="row">
                    <Icon icon={faCheckCircle} size="S" color="darkGreen" />
                    <Text variant="caption">{t("Garantie illimitée et support dédié")}</Text>
                  </Layout>
                  <Layout spacing={2} direction="row">
                    <Icon icon={faCheckCircle} size="S" color="darkGreen" />
                    <Text variant="caption">{t("Livraison rapide partout en Europe")}</Text>
                  </Layout>
                  <Layout spacing={2} direction="row">
                    <Icon icon={faCheckCircle} size="S" color="darkGreen" />
                    <Text variant="caption">{t("Une plateforme unique pour tout gérer")}</Text>
                  </Layout>
                </Layout>
                <Layout spacing={0.5}>
                  <Text variant="caption">{t("Des questions ?")}</Text>
                  <Link
                    color="secondary"
                    bold
                    label={t("Contacter un expert Fleet")}
                    href={`${import.meta.env.REACT_APP_WEBSITEURL}/${i18n.language}/demo`}
                  />
                </Layout>
              </Layout>
            </Layout>
          </Layout>

          <Layout className={styles.registrationForm} spacing={3}>
            <Layout spacing={3}>
              <Layout spacing={5}>
                <Heading variant="h4" textAlign="center">
                  {t("Créez votre compte Fleet gratuitement")}
                </Heading>

                <Layout spacing={0.5}>
                  <TextField
                    onChange={onChange}
                    value={userInfo.firstName || ""}
                    name="firstName"
                    placeholder={getLocalePlaceholder(initialSelectedLanguageVariation, "firstName")}
                    required
                    label={t("Prénom")}
                    error={errors?.firstName}
                  />
                  {errors?.firstName && (
                    <Text variant="caption" color="error">
                      {t(errors?.firstName)}
                    </Text>
                  )}
                </Layout>
              </Layout>

              <Layout spacing={0.5}>
                <TextField
                  onChange={onChange}
                  value={userInfo.lastName || ""}
                  name="lastName"
                  placeholder={getLocalePlaceholder(initialSelectedLanguageVariation, "lastName")}
                  required
                  label={t("Nom")}
                  error={errors?.lastName}
                />
                {errors?.lastName && (
                  <Text variant="caption" color="error">
                    {t(errors?.lastName)}
                  </Text>
                )}
              </Layout>

              <Layout spacing={0.5}>
                <TextField
                  onChange={onChange}
                  value={userInfo.email || ""}
                  name="email"
                  placeholder={getLocalePlaceholder(initialSelectedLanguageVariation, "email")}
                  label={t("E-mail professionnel")}
                  required
                  error={errors?.email}
                />
                {errors?.email && (
                  <Text variant="caption" color="error">
                    {t(errors?.email)}
                  </Text>
                )}
              </Layout>

              <Layout spacing={0.5}>
                <PhoneInput
                  value={userInfo.phone}
                  onChange={onPhoneChange}
                  country={userInfo.phone_country}
                  setIsPhoneValid={setIsPhoneValid}
                  error={errors?.phone}
                  required
                />
                {errors?.phone && (
                  <Text variant="caption" color="error">
                    {t(errors?.phone)}
                  </Text>
                )}
              </Layout>

              <Layout spacing={0.5}>
                <Select
                  dataCy="companySize"
                  onChange={(option) => onSelectChange("company_size", option)}
                  options={companySizeOptions.map((option) => ({
                    ...option,
                    label: t(option.label),
                  }))}
                  label={t("Taille de votre entreprise")}
                  required
                  error={errors?.company_size}
                />
                {errors?.company_size && (
                  <Text variant="caption" color="error">
                    {t(errors?.company_size)}
                  </Text>
                )}
              </Layout>

              <Text variant="caption" color="secondary">
                <Trans>
                  En soumettant ce formulaire, je confirme que j'ai lu la{" "}
                  <a
                    target="_new"
                    href={`${import.meta.env.REACT_APP_WEBSITEURL}/${i18n.language}/privacy-gdpr`}
                    className={styles.privacyPolicyLink}
                  >
                    politique de confidentialité
                  </a>{" "}
                  et que j'accepte le traitement de mes données personnelles par Fleet.
                </Trans>
              </Text>
              <Button
                data-track="click_register"
                fullWidth
                variant="contained"
                loading={isLoading}
                color="darkGreen"
                type="submit"
                label={t("S'inscrire")}
              />
            </Layout>
            <Layout>
              <Layout spacing={2}>
                <Separator label={t("Ou")} orientation="horizontal" />
              </Layout>
            </Layout>
            <Layout>
              <HasAccountAlready />
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </form>
  );
};

export default RegistrationForm;
