import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { Alert, Button, Drawer, Layout, Select, Text, TextField } from "tarmac-v3";
import countries from "src/data/countryByISO.json";

const StripeSEPAFormModal = (props) => {
  const { open, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [accountantInfo, setAccountantInfo] = useState({});
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToastContext();

  const [addresses, setAddresses] = useState([]);
  const onInputChange = (e) => setAccountantInfo((prevInfo) => ({ ...prevInfo, [e.target.name]: e.target.value }));
  const onAddressChange = (option) => setAccountantInfo((prevInfo) => ({ ...prevInfo, address: option }));
  const onCountryChange = (option) => setAccountantInfo((prevInfo) => ({ ...prevInfo, country: option }));

  const countryOptions = countries.map((country) => ({
    label: country.name,
    value: country.alpha_2,
    selected: accountantInfo.country === country.alpha_2,
  }));

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    setMessage("");

    if (!accountantInfo.name || !accountantInfo.country || !accountantInfo.address || !accountantInfo.email) {
      setMessage(t("Veuillez remplir tous les champs obligatoires"));
      setIsLoading(false);

      return;
    }

    if (accountantInfo.email.indexOf("@") === -1) {
      setMessage(t("Veuillez entrer l'adresse mail du contact financier"));
      setIsLoading(false);

      return;
    }

    if (!stripe || !elements) {
      setIsLoading(false);

      return;
    }

    if (currentUser.company.financial_contact !== accountantInfo.email) {
      await currentUser.api.modifyCompany({ financial_contact: accountantInfo.email });
    }

    const iban = elements.getElement(IbanElement);

    const { data: paymentInfo } = await currentUser.api.createPaymentIntent({ paymentMethod: "sepa_debit" });

    const result = await stripe.confirmSepaDebitSetup(paymentInfo.client_secret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountantInfo.name,
          email: accountantInfo.email,
          address: {
            city: accountantInfo.address.city,
            country: accountantInfo.country,
            postal_code: accountantInfo.address.zip,
            line1: accountantInfo.address.address1,
          },
        },
      },
    });

    if (result.error) {
      let message;

      switch (result.error.type) {
        case "validation_error":
          message = t("Le numéro IBAN est invalide, veuillez réessayer");
          break;
        case "parameter_missing":
          message = t("Un paramètre est manquant, veuillez réessayer");
          break;
        default:
          message = t("Une erreur est survenue, veuillez réessayer");
      }

      if (result.error.code === "bank_account_unusable") {
        message = t("Votre banque n'autorise pas les prélèvements SEPA, veuillez utiliser un autre moyen de paiement");
      }

      setMessage(message);
    } else {
      try {
        await currentUser.api.createPaymentMethod({ paymentMethodStripeId: result.setupIntent.payment_method });
      } catch (err) {
        addToast(t("Une erreur est survenue"));
      }

      addToast(t("Mandat SEPA bien enregistré"), "success");
      onClose();
    }

    setIsLoading(false);
  }, [
    accountantInfo.address,
    accountantInfo.country,
    accountantInfo.email,
    accountantInfo.name,
    addToast,
    currentUser.api,
    elements,
    onClose,
    stripe,
    t,
  ]);

  const ibanElementOptions = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "FR",
    style: { base: { fontSize: "14px" }, empty: { color: "#7E7E7E" } },
  };

  const defaultCountryCode = useMemo(() => {
    const selectedCountry = countryOptions.find((country) => currentUser?.company.country === country?.label);

    return selectedCountry?.value;
  }, [countryOptions, currentUser?.company.country]);

  useEffect(() => {
    setAccountantInfo({
      email: currentUser?.company.financial_contact,
      name: currentUser?.company.legal_name,
      country: defaultCountryCode,
      address: currentUser?.company.addresses.find((a) => a.is_default),
    });

    setAddresses(
      currentUser?.company.addresses
        .filter((a) => (a.address1 || a.address2) && a.zip && a.city)
        .map((a) => ({
          label: `${a.address1}, ${a.city} - ${a.country}`,
          value: a,
        })),
    );
  }, [currentUser, defaultCountryCode]);

  const customIbanStyle = {
    border: "1px solid",
    padding: "8px 12px",
    borderRadius: "4px",
    height: "38px",
  };

  const DrawerActions = useMemo(
    () => [
      <>
        <Button variant="outlined" color="secondary" label={t("Annuler")} fullWidth onClick={onClose} />
        <Button
          variant="contained"
          color="darkGreen"
          label={t("Enregistrer")}
          loading={isLoading}
          fullWidth
          onClick={handleSubmit}
        />
      </>,
    ],
    [handleSubmit, isLoading, onClose, t],
  );

  return (
    <Drawer open={open} onClose={onClose} title={t("Nouveau mandat SEPA")} Actions={DrawerActions}>
      <Layout direction="column" gap={2} isScrollable>
        {message && (
          <Alert severity="error" color="error" fullWidth>
            {message}
          </Alert>
        )}
        <Alert severity="warning" color="warning" fullWidth>
          {t("Ce nouveau mandat SEPA remplacera automatiquement l'ancien pour tous les prélèvements.")}
        </Alert>
        <Layout direction="column">
          <div style={customIbanStyle}>
            <IbanElement options={ibanElementOptions} />
          </div>
        </Layout>
        <TextField
          onChange={onInputChange}
          value={accountantInfo.email || ""}
          name="email"
          required
          label={t("Adresse mail")}
        />
        <TextField
          onChange={onInputChange}
          value={accountantInfo.name || ""}
          name="name"
          required
          label={t("Titulaire du compte (raison sociale)")}
        />
        <Select
          value={accountantInfo.address}
          onChange={onAddressChange}
          options={addresses.map((option) => ({ ...option, label: t(option.label) }))}
          label={t("Adresse de facturation")}
          required
        />
        <Select
          value={accountantInfo.country}
          onChange={onCountryChange}
          options={countryOptions.map((option) => ({ ...option, label: t(option.label) }))}
          label={t("Pays")}
          required
        />
        <Text variant="caption" color="secondary" textAlign="justify">
          {t(
            "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) FLEET et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Vous avez, entre autres, le droit de vous faire rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque. La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.",
          )}
        </Text>
      </Layout>
    </Drawer>
  );
};

export default StripeSEPAFormModal;
