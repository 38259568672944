import { useTranslation } from "react-i18next";
import { faArrowTurnDownRight } from "@fortawesome/pro-solid-svg-icons";
import { Icon, Layout, Text } from "tarmac-v3";
import type { SxProps } from "@mui/system";
import type { PrinterCharacteristics } from "src/types/models/PrinterCharacteristics.type";
import type { Price } from "src/types/models/Price.type";
import { formatPrice } from "../utils/formatPrice";

interface PrinterMaintenancePriceProps {
  printerCharacteristics: PrinterCharacteristics;
  printerMaintenancePrice: Price;
  currency: string;
  deviceNumber?: number;
  sx?: SxProps;
}

export function PrinterMaintenancePrice({
  printerCharacteristics,
  printerMaintenancePrice,
  currency,
  deviceNumber,
  sx,
}: PrinterMaintenancePriceProps) {
  const { t } = useTranslation();
  return (
    <Layout direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={sx}>
      <Layout direction="row" alignItems="center" spacing={0.5}>
        <Icon icon={faArrowTurnDownRight} size="S" fontSize={12} style={{ fontWeight: 100 }} />
        <Text variant="caption" color="secondary" sx={{ marginLeft: 1 }}>
          {printerCharacteristics.has_maintenance_price
            ? `${t("Maintenance")} x${deviceNumber} : ${printerCharacteristics.pages_per_month} ${t("copies/mois")}`
            : `${printerCharacteristics.pages_per_month} ${t("copies/mois conseillées")}`}
        </Text>
      </Layout>
      {printerCharacteristics.has_maintenance_price && (
        <Text variant="caption" color="secondary">
          {`${formatPrice(printerMaintenancePrice.amount, currency, 3)}${t("/copie")}`}
        </Text>
      )}
    </Layout>
  );
}
