export interface PrinterCharacteristics {
  has_maintenance_price: boolean;
  pages_per_month: string;
  paper_format: (typeof PrinterPaperFormat)[keyof typeof PrinterPaperFormat];
  scanner_type: (typeof PrinterScannerType)[keyof typeof PrinterScannerType];
}

export const PrinterPaperFormat = {
  A4: "A4",
  "A3/A4": "A3/A4",
} as const;

export const PrinterScannerType = {
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
} as const;

export const PrinterScannerLabels = {
  [PrinterScannerType.AUTOMATIC]: "Automatique",
  [PrinterScannerType.MANUAL]: "Manuel",
} as const;
