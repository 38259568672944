export const LANGUAGES = {
  FR: "fr",
  ES: "es",
  DE: "de",
  EN: "en",
};

export const LANGUAGES_LIST = Object.values(LANGUAGES);

export const LANGUAGE_VARIATIONS = {
  FR: "fr",
  ES: "es",
  DE: "de",
  US: "us",
  GB: "gb",
};

export const LANGUAGE_VARIATIONS_LIST = Object.values(LANGUAGE_VARIATIONS);

export const LANGUAGE_MAPPING = {
  [LANGUAGE_VARIATIONS.FR]: LANGUAGES.FR,
  [LANGUAGE_VARIATIONS.ES]: LANGUAGES.ES,
  [LANGUAGE_VARIATIONS.DE]: LANGUAGES.DE,
  [LANGUAGE_VARIATIONS.US]: LANGUAGES.EN,
  [LANGUAGE_VARIATIONS.GB]: LANGUAGES.EN,
};

export const COUNTRIES = {
  FRANCE: "France",
  SPAIN: "Spain",
  GERMANY: "Germany",
  UNITED_KINGDOM: "United Kingdom",
  UNITED_STATES: "United States",
  OTHER: "Other",
};

export const COUNTRIES_LIST = Object.values(COUNTRIES);

export const COUNTRIES_ISO_TO_NAME_MAPPING = {
  FR: COUNTRIES.FRANCE,
  ES: COUNTRIES.SPAIN,
  DE: COUNTRIES.GERMANY,
  GB: COUNTRIES.UNITED_KINGDOM,
  US: COUNTRIES.UNITED_STATES,
};

export const COUNTRIES_NAME_TO_ISO_MAPPING = Object.fromEntries(
  Object.entries(COUNTRIES_ISO_TO_NAME_MAPPING).map(([iso, name]) => [name, iso]),
);

export const CURRENCIES = {
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
};

export const CURRENCIES_LIST = Object.values(CURRENCIES);

export const CURRENCIES_WITH_OTHER = {
  ...CURRENCIES,
  OTHER: "Other",
};

export const CURRENCIES_WITH_OTHER_LIST = Object.values(CURRENCIES_WITH_OTHER);

export const CURRENCIES_LANGUAGE_VARIATIONS_MAPPING = {
  [CURRENCIES.EUR]: `${LANGUAGES.FR}-${LANGUAGE_VARIATIONS.FR.toUpperCase()}`,
  [CURRENCIES.USD]: `${LANGUAGES.EN}-${LANGUAGE_VARIATIONS.US.toUpperCase()}`,
  [CURRENCIES.GBP]: `${LANGUAGES.EN}-${LANGUAGE_VARIATIONS.GB.toUpperCase()}`,
};

export const CURRENCY_SYMBOLS = {
  [CURRENCIES.EUR]: "€",
  [CURRENCIES.USD]: "$",
  [CURRENCIES.GBP]: "£",
};

export const COUNTRY_CURRENCY_MAPPING = {
  [COUNTRIES.FRANCE]: CURRENCIES.EUR,
  [COUNTRIES.SPAIN]: CURRENCIES.EUR,
  [COUNTRIES.GERMANY]: CURRENCIES.EUR,
  [COUNTRIES.UNITED_KINGDOM]: CURRENCIES.GBP,
  [COUNTRIES.UNITED_STATES]: CURRENCIES.USD,
};
