import * as Sentry from "@sentry/react";

/**
 * Sanitize extra in the event of passing an error in the payload
 * @param metadata - Additional metadata to sanitize
 */
function prepareMetadata(metadata: Record<string, any> | null): Record<string, any> {
  if (!metadata) return {};
  return Object.keys(metadata).reduce((acc, key) => {
    let value = metadata[key];
    if (value instanceof Error) {
      value = value.toString();
    }
    return { ...acc, [key]: value };
  }, {});
}

/**
 * Frontend Logger utility class for consistent logging and error reporting.
 */
class LoggerClass {
  /**
   * Log an error message and send it to Sentry.
   * @param message - The error message
   * @param data - Optional error object and extra data
   */
  static error(message: string, data: { error?: Error | null; extra?: Record<string, any> } = {}) {
    console.error(`[ERROR] ${message}`);
    if (data.error) console.error(data.error);

    if (process.env.NODE_ENV !== "development") {
      if (data.error) {
        // Wrap the original error to preserve both the original error and have a consistent message
        const wrappedError = new Error(message);
        wrappedError.cause = data.error;
        Sentry.captureException(wrappedError, {
          extra: {
            ...data.extra,
            originalError: {
              message: data.error.message,
              name: data.error.name,
              stack: data.error.stack,
            },
          },
        });
      } else {
        Sentry.captureException(new Error(message), { extra: data.extra });
      }
    }
  }

  /**
   * Log a warning message and send it to Sentry.
   * @param message - The warning message
   * @param extra - Additional information to log
   */
  static warning(message: string, extra: Record<string, any> | null = null) {
    const timestamp = Date.now();
    console.warn(`[WARNING] ${message}`);

    if (process.env.NODE_ENV !== "development") {
      Sentry.addBreadcrumb({
        category: "info",
        message,
        level: "warning",
        data: prepareMetadata(extra),
        timestamp: timestamp / 1000, // sentry expects seconds
      });
    }
  }

  /**
   * Log an info message and add it as a breadcrumb in Sentry.
   * @param message - The info message
   * @param extra - Additional information to log
   */
  static info(message: string, extra: Record<string, any> | null = null) {
    const { noSentry, ...rest } = extra ?? {};
    const timestamp = Date.now();
    console.info(`[INFO] ${message}`);

    if (process.env.NODE_ENV !== "development" && !noSentry) {
      Sentry.addBreadcrumb({
        category: "info",
        message,
        level: "info",
        data: prepareMetadata(rest),
        timestamp: timestamp / 1000,
      });
    }
  }

  /**
   * Log a debug message (only in non-production environments).
   * @param message - The debug message
   * @param extra - Additional information to log
   */
  static debug(message: string, extra: Record<string, any> | null = null) {
    if (process.env.NODE_ENV !== "production") {
      console.debug(`[DEBUG] ${message} ${extra ? JSON.stringify(extra) : ""}`);
    }
    // We don't send debug messages to Sentry to avoid noise
  }
}

export const Logger = LoggerClass;
export default LoggerClass;
