import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Checkbox, Drawer, Layout, Select, Separator, Text, TextField, theme } from "tarmac-v3";
import PhoneInput from "src/components/utils/PhoneInput";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import CompanyAddressesSuggestions from "./CompanyAddressesSuggestions";

const CompanyAddressesModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, address, deviceCountry, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();

  const { t } = useTranslation();

  // GENERAL STATES
  const [errors, setErrors] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  // INITIALISATION STATES & VARIABLES
  const initialAddress = {
    label: address?.label || "",
    address1: address?.address1 || "",
    address2: address?.address2 || "",
    city: address?.city || "",
    zip: address?.zip || "",
    country: address?.country || deviceCountry?.name || "",
    type: address?.type || "OFFICE",
    firstName: address ? address.firstName : currentUser.firstName || "",
    lastName: address ? address.lastName : currentUser.lastName || "",
    phone: address ? address.phone : currentUser.phone || null,
    phone_country: address ? address.phone_country : currentUser.phone_country || null,
    comment: address?.comment || "",
    is_default: address?.is_default,
  };
  const [addressInfo, setAddressInfo] = useState(initialAddress);
  const [isLoading, setIsLoading] = useState(false);

  // Close modal (cancel)
  const onCloseAddressModal = () => {
    setErrors("");
    onClose();
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddressInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setAddressInfo((prevInfo) => ({ ...prevInfo, phone, phone_country: countryPhone }));
  };

  const handleSelectCountry = (e) => {
    const selectedCountry = e.label;

    setAddressInfo((prevInfo) => ({ ...prevInfo, country: selectedCountry }));
  };

  // Check if modal button should be disabled
  const buttonDisabled = () => {
    const missingFields = [];

    if (addressInfo.label?.trim() === "") {
      missingFields.push(t("Intitulé de l'adresse"));
    }

    if (addressInfo.address1?.trim() === "") {
      missingFields.push(t("Adresse"));
    }

    if (addressInfo.zip?.trim() === "") {
      missingFields.push(t("Code postal"));
    }

    if (addressInfo.city?.trim() === "") {
      missingFields.push(t("Ville"));
    }

    if (addressInfo.country?.trim() === "") {
      missingFields.push(t("Pays"));
    }

    if (addressInfo.lastName?.trim() === "") {
      missingFields.push(t("Nom"));
    }

    if (addressInfo.firstName?.trim() === "") {
      missingFields.push(t("Prénom"));
    }

    if (!addressInfo.phone || !isPhoneValid) {
      missingFields.push(t("Téléphone"));
    }

    if (!addressInfo.phone_country) {
      missingFields.push(t("Pays du téléphone"));
    }

    if (!addressInfo.type) {
      missingFields.push(t("Type d'adresse"));
    }

    if (missingFields.length > 0) {
      return {
        disabled: true,
        message: `${t("Certains champs obligatoires sont manquants:")} ${missingFields.join(", ")}`,
      };
    }

    return {
      disabled: false,
      message: "",
    };
  };

  // Create a new address or update it
  const saveChanges = async (e) => {
    e.preventDefault();
    try {
      const { disabled, message } = buttonDisabled();

      if (disabled) {
        setErrors(message);

        return;
      }

      setIsLoading(true);
      const addressConfig = {
        address1: addressInfo.address1,
        address2: addressInfo.address2,
        city: addressInfo.city,
        zip: addressInfo.zip,
        country: addressInfo.country,
        type: addressInfo.type,
        label: addressInfo.label,
        firstName: addressInfo.firstName,
        lastName: addressInfo.lastName,
        phone: addressInfo.phone,
        phone_country: addressInfo.phone_country,
        comment: addressInfo.comment,
        is_default: addressInfo.is_default,
      };

      if (addressInfo.phone && !isPhoneValid) {
        addToast(t("Le numéro de téléphone n'est pas valide"));
        setIsLoading(false);

        return;
      }
      if (address) {
        // MODIFY ADDRESS FROM COCKPIT
        const updatedAddress = await currentUser.api.modifyAddress(address.id, addressConfig);
        addToast(t("Adresse {{ addressName }} modifiée avec succès", { addressName: addressConfig.label }), "success");
        onSave(updatedAddress.data);
        setIsLoading(false);
      } else {
        // ADD ADDRESS FROM COCKPIT
        const newAddress = await currentUser.api.addAddress(addressConfig);

        addToast(t("Adresse {{ addressName }} créée avec succès", { addressName: addressConfig.label }), "success");
        onSave(newAddress.data);
        setIsLoading(false);
      }

      onCloseAddressModal();
    } catch (e) {
      setIsLoading(false);
      addToast(t(e.response.data.message));
    }
  };

  const addressTypes = [
    { value: "OFFICE", label: t("Bureau") },
    { value: "HOME", label: t("Domicile") },
    { value: "PO_BOX", label: t("Boîte postale") },
  ];

  const DrawerActions = (
    <Layout direction="row" spacing={2} fullWidth>
      <Button fullWidth variant="outlined" color="secondary" onClick={onCloseAddressModal} label={t("Annuler")} />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={saveChanges}
        loading={isLoading}
        disabled={isLoading || buttonDisabled().disabled}
        label={t("Enregistrer")}
      />
    </Layout>
  );

  return (
    <Drawer
      open
      anchor="right"
      title={t("Ajouter/modifier une adresse")}
      onClose={onCloseAddressModal}
      Actions={DrawerActions}
    >
      <Layout direction="column" spacing={3} isScrollable>
        {errors && (
          <Alert severity="error" onClose={() => setErrors("")}>
            {errors}
          </Alert>
        )}

        <Text variant="h6" bold>
          {t("Contact livraison")}
        </Text>

        <Layout direction="column" spacing={2}>
          <Layout direction="row" spacing={2}>
            <TextField
              label={t("Prénom")}
              name="firstName"
              required
              value={addressInfo.firstName || ""}
              onChange={handleChange}
              placeholder="Jean"
              fullWidth
              sx={{
                "& .MuiFormLabel-asterisk": {
                  color: `${theme.palette.red[500]}`,
                },
              }}
            />

            <TextField
              label={t("Nom")}
              name="lastName"
              required
              value={addressInfo.lastName || ""}
              onChange={handleChange}
              placeholder="Dupont"
              fullWidth
              sx={{
                "& .MuiFormLabel-asterisk": {
                  color: `${theme.palette.red[500]}`,
                },
              }}
            />
          </Layout>
          <Layout sx={{ maxWidth: "300px" }}>
            <PhoneInput
              required
              value={addressInfo.phone}
              country={addressInfo.phone_country}
              onChange={handlePhoneChange}
              setIsPhoneValid={setIsPhoneValid}
            />
          </Layout>
        </Layout>

        <Separator />

        <Layout direction="column">
          {addressInfo.label && (
            <Text variant="h6" bold>
              {addressInfo.label}
            </Text>
          )}
          <Checkbox
            checked={addressInfo.is_default === 1}
            onChange={() =>
              setAddressInfo({
                ...addressInfo,
                is_default: addressInfo.is_default === 1 ? 0 : 1,
              })
            }
            label={t("Définir comme adresse par défaut")}
          />
        </Layout>

        <Separator />

        <TextField
          label={t("Intitulé de l'adresse")}
          name="label"
          value={addressInfo.label || ""}
          onChange={handleChange}
          required
          placeholder={t("Siège social")}
          sx={{
            "& .MuiFormLabel-asterisk": {
              color: `${theme.palette.red[500]}`,
            },
          }}
        />

        <Select
          label={t("Type d'adresse")}
          required
          placeholder={t("Type d'adresse")}
          value={addressInfo.type}
          options={addressTypes}
          onChange={(option) =>
            setAddressInfo({
              ...addressInfo,
              type: option,
            })
          }
          sx={{
            "& .MuiFormLabel-asterisk": {
              color: `${theme.palette.red[500]}`,
            },
          }}
        />

        {addressInfo.type === "PO_BOX" && (
          <Alert icon={false} severity="warning">
            {t("L'envoi en boîte postale peut impacter les délais de livraison.")}
          </Alert>
        )}
        <Layout direction="column" spacing={2}>
          <CompanyAddressesSuggestions companyInfo={addressInfo} setCompanyInfo={setAddressInfo} />

          <TextField
            label={t("Complément d'adresse")}
            name="address2"
            value={addressInfo.address2 || ""}
            onChange={handleChange}
            placeholder={t("Apt, suite, unité etc…")}
            maxLength={70}
          />

          <Layout direction="row" spacing={2}>
            <TextField
              label={t("Code postal")}
              name="zip"
              required
              value={addressInfo.zip || ""}
              onChange={handleChange}
              placeholder="75008"
              fullWidth
              sx={{
                "& .MuiFormLabel-asterisk": {
                  color: `${theme.palette.red[500]}`,
                },
              }}
            />
            <TextField
              label={t("Ville")}
              name="city"
              required
              value={addressInfo.city || ""}
              onChange={handleChange}
              placeholder="Paris"
              fullWidth
              sx={{
                "& .MuiFormLabel-asterisk": {
                  color: `${theme.palette.red[500]}`,
                },
              }}
            />
          </Layout>

          <TextField
            label={t("Pays")}
            required
            placeholder="Belgique"
            value={addressInfo.country || ""}
            onChange={handleSelectCountry}
            sx={{
              "& .MuiFormLabel-asterisk": {
                color: `${theme.palette.red[500]}`,
              },
            }}
          />

          <TextField
            label={t("Instructions de livraison")}
            name="comment"
            value={addressInfo.comment || ""}
            onChange={handleChange}
            placeholder={t("Code, interphone, bâtiment, étage, etc…")}
            variant="outlined"
            type="text"
            multiline
            minRows={2}
            maxRows={3}
          />
        </Layout>
      </Layout>
    </Drawer>
  );
};

export default CompanyAddressesModal;
