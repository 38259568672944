import { Layout, Text } from "tarmac-v3";
import type { LayoutProps } from "tarmac-v3/dist/components/Layout";
import type { TypographyProps } from "tarmac-v3/dist/components/Text";

interface ProductSpecificationLayoutProps extends LayoutProps {
  label: string;
  value: string;
  labelTextProps?: TypographyProps;
  valueTextProps?: TypographyProps;
}

export function ProductSpecificationLayout({
  label,
  value,
  labelTextProps,
  valueTextProps,
  ...props
}: ProductSpecificationLayoutProps) {
  return (
    <Layout direction="column" {...props}>
      <Text {...labelTextProps}>{label}</Text>
      <Text {...valueTextProps}>{value}</Text>
    </Layout>
  );
}
