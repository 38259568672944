import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";

import { useToastContext } from "src/contexts/ToastContext";
import { useUserContext } from "src/contexts/UserContext";
import useCatalogFilters from "src/hooks/useCatalogFilters";
import useCatalogNavigation from "src/hooks/useCatalogNavigation";

import { filterProductGroups } from "src/tools/ProductHelpers";

const countries = {
  fr: "France",
  es: "Spain",
  de: "Germany",
  uk: "United Kingdom",
  en: "Other",
};

const Context = createContext({});

export const useCatalogContext = () => useContext(Context);

export const CatalogProvider = ({ children }) => {
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { homeUrl } = useCatalogNavigation();
  const history = useHistory();

  const [productGroups, setProductGroups] = useState([]);
  const [shoppingLists, setShoppingLists] = useState([]);
  const [productGroup, setProductGroup] = useState(null);
  const [catalogBanner, setCatalogBanner] = useState(null);
  const [filteredProductGroups, setFilteredProductGroups] = useState([]);
  const { breadcrumb, productSlug } = useCatalogNavigation();
  const { filters, initialFilters, onChangeFilters, setFilters, resetFilter, resetFilters, filtersCount } =
    useCatalogFilters();

  const companyCountry = user.getMainOrOtherCountry();

  const applyFilters = useCallback(
    (productGroupsToFilter) => {
      const currentCurrency = user.getCompanyCurrency();

      const newFilteredProductGroups = filterProductGroups(
        filters,
        productGroupsToFilter || productGroups,
        currentCurrency,
      );

      setFilteredProductGroups(newFilteredProductGroups);
    },
    [filters, productGroups],
  );

  const fetchProductGroups = useCallback(async () => {
    try {
      const params = {
        visibility: ["AVAILABLE"],
        country: companyCountry,
      };
      const endpoint = `?${qs.stringify(params)}`;
      const pgRes = await user.globalApi.getProductGroups(endpoint);
      const noEmptyProductGroup = pgRes.data.filter((pg) => pg.products.length > 0);

      setProductGroups(noEmptyProductGroup);
    } catch (_err) {
      addToast(t("Une erreur est survenue lors de la récupération des produits"));
    }
  }, [user, addToast, t]);

  const fetchProductGroup = useCallback(async () => {
    try {
      if (productSlug) {
        const params = {
          source: "cockpit",
          country: companyCountry,
        };
        const endpoint = `?${qs.stringify(params)}`;
        const productGroupRes = await user?.globalApi.getProductGroup(productSlug, endpoint);

        setProductGroup(productGroupRes.data);
      }
    } catch (_err) {
      history.push(homeUrl);
    }
  }, [productSlug, user]);

  const fetchCatalogBanner = useCallback(async () => {
    const catalogBannerRes = await user?.globalApi.getCatalogBanner();
    const banner = { title: catalogBannerRes.data.title, url: catalogBannerRes.data.url };
    const userLanguageCountry = countries[user.language.substring(0, 2)];

    if (typeof catalogBannerRes.data.img_url === "object" && Object.keys(catalogBannerRes.data.img_url).length > 0) {
      for (const key of Object.keys(catalogBannerRes.data.img_url)) {
        if (key.includes(userLanguageCountry)) {
          banner[key] = catalogBannerRes.data.img_url[key];
        }
      }
    }

    setCatalogBanner(banner);
  }, [user]);

  const fetchShoppingLists = useCallback(async () => {
    try {
      const { data: shoppingLists } = await user.api.getShoppingLists();

      setShoppingLists(shoppingLists);
    } catch (_err) {
      addToast(t("Une erreur est survenue lors de la récupération des listes d'achat"));
    }
  }, [user, addToast, t]);

  useEffect(() => {
    fetchProductGroups();
    fetchCatalogBanner();
    fetchShoppingLists();
  }, [user]);

  useEffect(() => {
    if (productSlug) {
      fetchProductGroup();
    } else {
      setProductGroup(null);
    }
  }, [productSlug]);

  useEffect(() => {
    const universe = breadcrumb.find((bc) => bc.type === "UNIVERSE");
    const category = breadcrumb.find((bc) => bc.type === "CATEGORY");
    const categoryGroup = breadcrumb.find((bc) => bc.type === "CATEGORY_GROUP");

    if (!category && (categoryGroup || universe)) {
      setFilters({ ...initialFilters, category: categoryGroup?.name || universe.name });
    } else if (category) {
      setFilters({ ...filters, category: category.name });
    } else {
      setFilters({ ...initialFilters });
    }
  }, [pathname]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const productGroupsCount = useMemo(
    () => filteredProductGroups.map((g) => g.products.length).reduce((a, b) => a + b, 0),
    [filteredProductGroups],
  );

  const minimumUsage = useMemo(() => {
    let minimumUsage = "";

    if (filters?.usages.includes("EXPERT")) {
      minimumUsage = "EXPERT";
    }

    if (filters?.usages.includes("ADVANCED")) {
      minimumUsage = "ADVANCED";
    }

    if (!filters?.usages.length || filters?.usages.includes("BASIC")) {
      minimumUsage = "BASIC";
    }

    return minimumUsage;
  }, [filters?.usages]);

  const values = useMemo(
    () => ({
      filters,
      filtersCount,
      initialFilters,
      onChangeFilters,
      setFilters,
      resetFilter,
      resetFilters,
      unfilteredProductGroups: productGroups,
      productGroups: filteredProductGroups,
      productGroupsCount,
      minimumUsage,
      productGroup,
      catalogBanner,
      shoppingLists,
      fetchShoppingLists,
    }),
    [
      filters,
      filtersCount,
      initialFilters,
      onChangeFilters,
      setFilters,
      resetFilter,
      resetFilters,
      productGroups,
      filteredProductGroups,
      productGroupsCount,
      minimumUsage,
      productGroup,
      catalogBanner,
      shoppingLists,
      fetchShoppingLists,
    ],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
