import { useUserContext } from "src/contexts/UserContext";
import { formatPrice } from "src/components/utils/formatPrice";

interface MoneyFormatProps {
  value: string;
}

function MoneyFormat({ value }: MoneyFormatProps) {
  const { user: currentUser } = useUserContext();

  if (!value) return "-";

  const currency = currentUser.getCompanyCurrency();
  return formatPrice(value, currency);
}

export default MoneyFormat;
