import { Button, Layout, Text, theme } from "tarmac-v3";
import useDeviceType from "src/hooks/useDeviceType";
import { faMinus, faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import styles from "./AddAndRemoveItemButton.module.scss";

const AddAndRemoveItemButton = (props) => {
  const { clickMinus, clickPlus, listLength } = props;
  const deviceType = useDeviceType();

  return (
    <Layout
      direction="row"
      className={styles.productActionsContainer}
      justifyContent={deviceType === "desktop" ? "flex-start" : "center"}
      sx={{ height: theme.spacing(4) }}
    >
      <Button
        startIcon={listLength === 1 ? faTrashAlt : faMinus}
        size="small"
        onClick={clickMinus}
        color="secondary"
        data-cy="decrementQuantity"
        sx={{ paddingRight: "4px" }}
      />
      <Layout direction="row" fullWidth className={styles.devicesCount} justifyContent="center" alignContent="center">
        <Text sx={{ padding: "0px 16px", fontSize: "12px" }} textAlign="center">
          {listLength}
        </Text>
      </Layout>
      <Button
        startIcon={faPlus}
        size="small"
        onClick={clickPlus}
        color="secondary"
        data-cy="incrementQuantity"
        sx={{ paddingRight: "4px" }}
      />
    </Layout>
  );
};

export default AddAndRemoveItemButton;
