import { useUserContext } from "src/contexts/UserContext";
import type { Device } from "src/types/models/Device.type";
import type { PrinterMaintenancePrice } from "src/types/models/PrinterMaintenancePrice.type";

export function usePrinterDeviceSpecifications(device: Device) {
  const { user } = useUserContext();
  const userCurrency = user.getCompanyCurrency();
  const isPrinterCategory = device.category === "PRINTER";
  const printerMaintenancePrice =
    device.product?.printer_maintenance_prices?.find((p: PrinterMaintenancePrice) => p.currency === userCurrency) ??
    null;
  const printerCharacteristics = device.product?.printer_characteristics ?? null;

  return {
    isPrinterCategory,
    printerMaintenancePrice,
    printerCharacteristics,
  };
}
