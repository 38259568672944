import { LANGUAGES, CURRENCIES } from "src/common/i18n-consts";

/**
 * Format a price to a string with the currency
 * @param price - The price to format
 * @param currency - The currency to format the price to
 * @param minimumFractionDigits - The minimum number of fraction digits to display
 * @returns The formatted price
 */
export const formatPrice = (price: string, currency: string, minimumFractionDigits: number | undefined = 2) => {
  const formattedPrice = parseFloat(price).toLocaleString(currency === CURRENCIES.EUR ? LANGUAGES.FR : LANGUAGES.EN, {
    style: "currency",
    currency,
    minimumFractionDigits,
  });

  return formattedPrice.replace(/\s/g, "");
};
