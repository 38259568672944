import React, { useState } from "react";
import { TextField, Layout, theme, Text } from "tarmac-v3";
import OutsideClickHandler from "src/components/utils/OutsideClickHandler";
import PlaceService from "src/tools/PlaceService";
import { useTranslation } from "react-i18next";
import useDebouncedEffect from "src/components/utils/useDebouncedEffect";
import { COUNTRIES_ISO_TO_NAME_MAPPING } from "src/common/i18n-consts";
import styles from "./CompanyAddressesModal.module.scss";

const CompanyAddressesSuggestions = (props) => {
  const { companyInfo, setCompanyInfo } = props;
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);
  const [isAddressSearchResultsVisible, setIsAddressSearchResultsVisible] = useState(false);

  const placeService = new PlaceService();

  // FUNCTIONS

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const handleSearch = async (e) => {
    if (!e) {
      return;
    }

    setCompanyInfo({
      ...companyInfo,
      address1: e.target.value,
    });

    if (!e.target.value || e.target.value.trim() === "") {
      clearSuggestions();
      return;
    }
    const suggestionsFromApi = await placeService.getPlaceIdFromText(e.target.value);

    if (!isAddressSearchResultsVisible) {
      setIsAddressSearchResultsVisible(true);
    }

    setSuggestions(suggestionsFromApi);
  };

  useDebouncedEffect(handleSearch, 500, [companyInfo.address1]);
  // Handle address select in results box
  const handleSelect = async (suggestion) => {
    const placeId = suggestion.place_id;
    const addressFromApi = await placeService.getAddressForPlaceId(placeId);

    const countryFromApi = addressFromApi.countryISO;
    const country = COUNTRIES_ISO_TO_NAME_MAPPING[countryFromApi] ?? addressFromApi.country;

    setCompanyInfo({
      ...companyInfo,
      ...addressFromApi,
      country,
      countryISO: countryFromApi,
      address1: suggestion.structured_formatting.main_text || "",
    });

    clearSuggestions();
  };

  return (
    <OutsideClickHandler action={() => setIsAddressSearchResultsVisible(false)}>
      <Layout className={styles.adressField}>
        <TextField
          required
          label={t("Adresse")}
          name="address1"
          placeholder={t("Entrez une adresse")}
          value={companyInfo.address1}
          onChange={handleSearch}
          sx={{
            "& .MuiFormLabel-asterisk": {
              color: `${theme.palette.red[500]}`,
            },
          }}
        />
        <Layout position="absolute" zIndex={1000} left={0} right={0} mt={5}>
          {suggestions.length > 0 && isAddressSearchResultsVisible && (
            <Layout
              direction="column"
              spacing={1}
              bgcolor="white"
              border={`1px solid ${theme.palette.grey[100]}`}
              borderRadius={1}
              boxShadow={`0px 4px 8px ${theme.palette.grey[80]}`}
            >
              {suggestions.map((suggestion) => (
                <Layout
                  key={suggestion.place_id}
                  onClick={() => handleSelect(suggestion)}
                  padding={1.5}
                  spacing={1}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "grey.100",
                    },
                  }}
                >
                  <Text color="primary" variant="body2">
                    {suggestion.description}
                  </Text>
                </Layout>
              ))}
            </Layout>
          )}
        </Layout>
      </Layout>
    </OutsideClickHandler>
  );
};

export default CompanyAddressesSuggestions;
