export enum OtherFurnitureCategories {
  ARMCHAIR = "ARMCHAIR",
  SOFA = "SOFA",
  TABLE = "TABLE",
  SHELVING_UNIT = "SHELVING_UNIT",
  STORAGE_CABINET = "STORAGE_CABINET",
  ACOUSTIC_PARTITION = "ACOUSTIC_PARTITION",
  LAMP = "LAMP",
}

export enum Brands {
  ACER_CHROMEBOOK = "acer-chromebook",
  APPLE = "apple",
  ASUS = "asus",
  BELKIN = "belkin",
  CHERRY = "cherry",
  DELL = "dell",
  DEVIALET = "devialet",
  EIZO = "eizo",
  ELGATO = "elgato",
  FLEET = "fleet",
  GENTIX = "gentix",
  GOOGLE = "google",
  HERMAN_MILLER = "herman-miller",
  HP = "hp",
  IIYAMA = "iiyama",
  JABRA = "jabra",
  LEET_DESIGN = "leet-design",
  LENOVO = "lenovo",
  LOGITECH = "logitech",
  MICROSOFT = "microsoft",
  NEC = "nec",
  OFFCOUSTIC = "offcoustic",
  PERSY_BOOTH = "persy-booth",
  RAZER = "razer",
  SAMSUNG = "samsung",
  SLEAN = "slean",
  SONY = "sony",
  VSEN = "vseven",
  WORK_LOUDER = "work-louder",
  WORK_WITH_ISLAND = "work-with-island",
  XIAOMI = "xiaomi",
  YEALINK = "yealink",
  ACTIU = "actiu",
  VITRA = "vitra",
  MUUTO = "muuto",
  KNOLL = "knoll",
  RICOH = "ricoh",
  BROTHER = "brother",
  CANON = "canon",
}

export const otherFurnitureCategories = [
  OtherFurnitureCategories.ARMCHAIR,
  OtherFurnitureCategories.SOFA,
  OtherFurnitureCategories.TABLE,
  OtherFurnitureCategories.SHELVING_UNIT,
  OtherFurnitureCategories.STORAGE_CABINET,
  OtherFurnitureCategories.ACOUSTIC_PARTITION,
  OtherFurnitureCategories.LAMP,
];

export const brands = [
    { value: Brands.ACER_CHROMEBOOK, label: "Acer Chromebook" },
    { value: Brands.APPLE, label: "Apple" },
    { value: Brands.ASUS, label: "Asus" },
    { value: Brands.BELKIN, label: "Belkin" },
    { value: Brands.CHERRY, label: "Cherry" },
    { value: Brands.DELL, label: "Dell" },
    { value: Brands.DEVIALET, label: "Devialet" },
    { value: Brands.EIZO, label: "EIZO" },
    { value: Brands.ELGATO, label: "Elgato" },
    { value: Brands.FLEET, label: "Fleet" },
    { value: Brands.GENTIX, label: "Gentix" },
    { value: Brands.GOOGLE, label: "Google" },
    { value: Brands.HERMAN_MILLER, label: "Herman Miller" },
    { value: Brands.HP, label: "HP" },
    { value: Brands.IIYAMA, label: "iiyama" },
    { value: Brands.JABRA, label: "Jabra" },
    { value: Brands.LEET_DESIGN, label: "Leet Design" },
    { value: Brands.LENOVO, label: "Lenovo" },
    { value: Brands.LOGITECH, label: "Logitech" },
    { value: Brands.MICROSOFT, label: "Microsoft" },
    { value: Brands.NEC, label: "NEC" },
    { value: Brands.OFFCOUSTIC, label: "Offcoustic" },
    { value: Brands.PERSY_BOOTH, label: "Persy Booths" },
    { value: Brands.RAZER, label: "Razer" },
    { value: Brands.SAMSUNG, label: "Samsung" },
    { value: Brands.SLEAN, label: "Slean" },
    { value: Brands.SONY, label: "Sony" },
    { value: Brands.VSEN, label: "VSeven" },
    { value: Brands.WORK_LOUDER, label: "Work Louder" },
    { value: Brands.WORK_WITH_ISLAND, label: "Work with Island" },
    { value: Brands.XIAOMI, label: "Xiaomi" },
    { value: Brands.YEALINK, label: "Yealink" },
    { value: Brands.ACTIU, label: "Actiu" },
    { value: Brands.VITRA, label: "Vitra" },
    { value: Brands.MUUTO, label: "Muuto" },
    { value: Brands.KNOLL, label: "Knoll" },
    { value: Brands.RICOH, label: "Ricoh" },
    { value: Brands.BROTHER, label: "Brother" },
    { value: Brands.CANON, label: "Canon" },
  ];

  export const otherFurnitureCategoryLabels = [
  { label: "Fauteuil", value: OtherFurnitureCategories.ARMCHAIR },
  { label: "Canapé", value: OtherFurnitureCategories.SOFA },
  { label: "Table", value: OtherFurnitureCategories.TABLE },
  { label: "Étagère", value: OtherFurnitureCategories.SHELVING_UNIT },
  { label: "Armoire", value: OtherFurnitureCategories.STORAGE_CABINET },
  { label: "Cloison acoustique", value: OtherFurnitureCategories.ACOUSTIC_PARTITION },
  { label: "Lampe", value: OtherFurnitureCategories.LAMP },
];
